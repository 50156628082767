.header{
    position: fixed;
    right: 20px;
    bottom: -40px;
    z-index: 1000;
   
}
.menu{
    width: 10vw;
    height: 10vh;
    margin-top: 100px;
    background-color:black;
    display: flex;
    justify-content: center;
    border-radius: 25px;
    position: relative;
} 