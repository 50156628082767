.button {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 50px;
    overflow: hidden;
}

.button .slider {
    position: relative;
    width: 100%;
    height: 100%;
}

.button .slider .el {
    width: 100%;
    height: 100%;
    background-color: #000;
}

.button .slider .el p {
    margin: 0;
}

.button .slider .el:nth-of-type(2) {
    background-color: #262626;
}

.button .slider .el:nth-of-type(2) p {
    color: #000;
}

.button .slider .el:hover .perspectiveText {
    transform: rotateX(90deg);
}

.button .slider .el:hover .perspectiveText p:nth-of-type(1) {
    transform: translateY(-100%);
    opacity: 0;
}

.button .slider .el:hover .perspectiveText p:nth-of-type(2) {
    opacity: 1;
}

.perspectiveText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    transition: transform 0.75s cubic-bezier(0.76, 0, 0.24, 1);
}

.perspectiveText p {
    transition: all 0.75s cubic-bezier(0.76, 0, 0.24, 1);
    pointer-events: none;
    text-transform: uppercase;
}

.perspectiveText p:nth-of-type(2) {
    position: absolute;
    transform-origin: top center;
    transform: rotateX(-90deg) translateY(9px);
    opacity: 0;
}